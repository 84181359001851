<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.locationId"></base-select>
      </el-form-item>
      <el-form-item label="物资">
        <base-select info="Goods" v-model="filter.goodsId"></base-select>
      </el-form-item>
      <el-form-item label="物资类别">
        <type-cascader
          v-model="filter.goodsTypeId"
          info="GoodsType"
        ></type-cascader>
      </el-form-item>
      <el-form-item label="本期无发生">
        <el-switch
          v-model="filter.showAll"
          active-text="显示"
          inactive-text="隐藏"
        ></el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <div class="tip">
      <el-row>
        <el-col :span="8">
          期初合计：
          <strong>￥{{ qcTotal | thousands }}</strong>
        </el-col>
        <el-col :span="8">
          本期合计：
          <strong>￥{{ currentTotal | thousands }}</strong>
        </el-col>
        <el-col :span="8">
          期末合计：
          <strong>￥{{ endTotal | thousands }}</strong>
        </el-col>
      </el-row>
    </div>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
    >
      <el-table-column
        label="物资编号"
        prop="goodsCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="物资名称"
        prop="goodsName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="规格"
        prop="goodsSpec"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单位"
        prop="goodsUnit"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="期初">
        <el-table-column label="数量" prop="qcQuantity" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.qcQuantity | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="单价" prop="qcPrice" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.qcPrice | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="金额" prop="qcAmount" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.qcAmount | thousands(2)
          }}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="本期">
        <el-table-column
          label="数量"
          prop="currentQuantity"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.currentQuantity | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="单价" prop="currentPrice" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.currentPrice | thousands
          }}</template>
        </el-table-column>
        <el-table-column
          label="金额"
          prop="currentAmount"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.currentAmount | thousands(2)
          }}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="期末">
        <el-table-column label="数量" prop="endQuantity" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.endQuantity | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="单价" prop="endPrice" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.endPrice | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="金额" prop="endAmount" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.endAmount | thousands(2)
          }}</template>
        </el-table-column>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import toExcel from "@/common/toExcel";
import tableHeight from "@/common/tableHeightMixins";
import TypeCascader from "@/components/TypeCascader";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
    TypeCascader,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  computed: {
    qcTotal() {
      let total = 0;
      this.data.forEach((item) => {
        total += this.amountFormat(item.qcAmount);
      });
      return this.amountFormat(total);
    },
    currentTotal() {
      let total = 0;
      this.data.forEach((item) => {
        total += this.amountFormat(item.currentAmount);
      });
      return this.amountFormat(total);
    },
    endTotal() {
      let total = 0;
      this.data.forEach((item) => {
        total += this.amountFormat(item.endAmount);
      });
      return this.amountFormat(total);
    },
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("GoodsStock/Store", params)
        .then((r) => {
          r.forEach((item) => {
            item.endQuantity = item.qcQuantity + item.currentQuantity;
            item.endAmount = item.qcAmount + item.currentAmount;
            item.qcPrice =
              item.qcQuantity == 0 ? 0 : item.qcAmount / item.qcQuantity;
            item.currentPrice =
              item.currentQuantity == 0
                ? 0
                : item.currentAmount / item.currentQuantity;
            item.endPrice =
              item.endQuantity == 0 ? 0 : item.endAmount / item.endQuantity;
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    dblclickTable(row) {
      this.$store.commit("setFilterCache", {
        name: "GoodsFlow",
        filter: {
          begin: this.filter.begin,
          end: this.filter.end,
          storeId: this.filter.locationId,
          goodsId: row.goodsId,
        },
      });
      this.$router.push({
        name: "GoodsFlow",
      });
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          goodsCode: item.goodsCode,
          goodsName: item.goodsName,
          goodsSpec: item.goodsSpec,
          goodsUnit: item.goodsUnit,
          qcQuantity: item.qcQuantity,
          qcPrice: item.qcPrice,
          qcAmount: this.amountFormat(item.qcAmount),
          currentQuantity: item.currentQuantity,
          currentPrice: item.currentPrice,
          currentAmount: this.amountFormat(item.currentAmount),
          endQuantity: item.endQuantity,
          endPrice: item.endPrice,
          endAmount: this.amountFormat(item.endAmount),
        });
      });
      let header = [
        [
          "物资编号",
          "物资名称",
          "规格",
          "单位",
          "期初",
          "",
          "",
          "本期",
          "",
          "",
          "结存",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "数量",
          "单价",
          "金额",
          "数量",
          "单价",
          "金额",
          "数量",
          "单价",
          "金额",
        ],
      ];
      let column = [
        "goodsCode",
        "goodsName",
        "goodsSpec",
        "goodsUnit",
        "qcQuantity",
        "qcPrice",
        "qcAmount",
        "currentQuantity",
        "currentPrice",
        "currentAmount",
        "endQuantity",
        "endPrice",
        "endAmount",
      ];
      toExcel(header, column, list, "仓库收发存", [
        {
          s: {
            c: 0, //开始列
            r: 0, //开始行
          },
          e: {
            c: 0, //结束列
            r: 1, //结束行
          },
        },
        {
          s: {
            c: 1, //开始列
            r: 0, //开始行
          },
          e: {
            c: 1, //结束列
            r: 1, //结束行
          },
        },
        {
          s: {
            c: 2, //开始列
            r: 0, //开始行
          },
          e: {
            c: 2, //结束列
            r: 1, //结束行
          },
        },
        {
          s: {
            c: 3, //开始列
            r: 0, //开始行
          },
          e: {
            c: 3, //结束列
            r: 1, //结束行
          },
        },
        {
          s: {
            c: 4, //开始列
            r: 0, //开始行
          },
          e: {
            c: 6, //结束列
            r: 0, //结束行
          },
        },
        {
          s: {
            c: 7, //开始列
            r: 0, //开始行
          },
          e: {
            c: 9, //结束列
            r: 0, //结束行
          },
        },
        {
          s: {
            c: 10, //开始列
            r: 0, //开始行
          },
          e: {
            c: 12, //结束列
            r: 0, //结束行
          },
        },
      ]);
    },
  },
};
</script>

<style>
</style>